<template>
  <div>
    <b-card no-body>
      <div>
        <vue-good-table
            :columns="columns"
            :is-loading.sync="isLoading"
            :pagination-options="{...{
              enabled: true,
              mode: 'pages',
              position: 'top',
             },...paginationOptions}"
            :rows="items"
            :search-options="{enabled: true}"
            :total-rows="totalRecords"
            max-height="400px"
            min-width="900px"
            theme="polar-bear"
            no-data="nooooo"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
        >
          <template
              slot="table-row"
              slot-scope="props"
          >
    <span v-if="props.column.field === 'title'">
      <router-link :to="{name: 'show-contract', params: { id: props.row.id } }">{{
          props.formattedRow[props.column.field]
        }}</router-link>
    </span>
            <span v-else>
      {{ props.formattedRow[props.column.field] }}
    </span>
          </template>
          <div slot="table-actions">
            <div class="d-flex align-items-center justify-content-end">
              <b-dropdown
                  v-if="isCanDo('addContract')"
                  :text="$i18n.t('user.contracts.addContract')"
                  class="mr-1 floatDropDown"
                  variant="primary"
              >
                <b-dropdown-item
                    href="#"
                >
                  {{ $i18n.t('user.contracts.uploadContract') }}
                </b-dropdown-item>
                <b-dropdown-item
                    :to="{ name: 'update-contract' }"
                >
                  {{ $i18n.t('user.contracts.addContractWithoutPdf') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="d-flex align-items-center justify-content-end mr-1 mt-1">
              <b-button
                  v-b-toggle.sidebar-right
                  block
                  variant="primary"
              >
                {{ $i18n.t('user.contracts.selectColumns') }}
              </b-button>
              <b-sidebar
                  id="sidebar-right"
                  right
              >
                <div class="sidebarContract">
                  <b-form-group
                      v-slot="{ ariaDescribedby }"
                      label="Select the Columns you want to display"
                  >
                    <b-form-checkbox-group
                        v-model="checkedOption"
                        :aria-describedby="ariaDescribedby"
                        :options="columnsOptions"
                        stacked
                        switches
                    />
                  </b-form-group>
                </div>
              </b-sidebar>
            </div>
          </div>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {VueGoodTable} from 'vue-good-table';
import {BFormCheckboxGroup} from 'bootstrap-vue';
import flatpickr from "flatpickr";
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    VueGoodTable,
    BFormCheckboxGroup
  },
  data() {
    return {
      checkedOption: [],
      items: [],
      columnsOptions: [],
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10
      },
      allCategories: [],
      repository: RepositoryFactory.create('contracts'),
      teamRepo: RepositoryFactory.create('teamsUser'),
    }
  },
  computed: {
    columns() {
      return this.getColumns()
    },
    paginationOptions(){
      return {
        nextLabel: this.$i18n.t("pagination.nextLabel"),
        prevLabel: this.$i18n.t("pagination.prevLabel"),
        rowsPerPageLabel: this.$i18n.t("pagination.rowsPerPageLabel"),
        ofLabel: this.$i18n.t("pagination.ofLabel"),
        pageLabel: this.$i18n.t("pagination.pageLabel"),
        allLabel: this.$i18n.t("pagination.allLabel"),
      }
    }
  },
  mounted() {
    const getInputFillterdType = document.querySelectorAll('.filter-th .vgt-input');
    getInputFillterdType.forEach((input) => {
      flatpickr(input, {dateFormat: 'Y-m-d',});
    });
  },
  created() {
    this.getAllCategories();
    this.getColumns()
    this.getData()
    this.getAllFieldsTemplatesName()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = {...this.serverParams, ...newProps};
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.getData();
    },
    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.getData();
    },
    getData() {
      this.teamRepo.getContracts(this.getCurrentTeam().id, this.serverParams)
          .then(res => {
            this.items = []
            const response = res.data
            response.data.forEach(item => {
              const data = {}
              data.id = item.id
              data.title = item.title
              data.category = item.category.name
              item.fields.forEach(field => {
                const fieldItem = JSON.parse(field.value)
                Object.assign(data, {[fieldItem.name]: fieldItem.value})
              })
              this.items.push(data)
            })
            this.totalRecords = response.meta.total
          })
    },
    getAllCategories() {
      this.utilsAppRepository().getCategories()
          .then(res => {
            this.allCategories = []
            const response = res.data
            response.data.forEach(item => {
              this.allCategories.push(item.name)
            })
          })
    },
    getAllFieldsTemplatesName() {
      this.utilsAppRepository().getFieldsTemplatesNames().then(res => {
        this.columnsOptions = []
        const response = res.data
        response.data.forEach(item => {
          const object = {
            value: {
              label: item.name,
              field: item.name,
            },
            text: item.name,
          }
          this.columnsOptions.push(object);
        })
      })
    },
    getColumns() {
      return [
        {
          label: this.$i18n.t('user.contracts.title'),
          field: 'title',
        },
        {
          label: this.$i18n.t('admin.category.category'),
          field: 'category',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Filter By Category', // placeholder for filter input
            filterDropdownItems: this.allCategories, // dropdown (with selected values) instead of text input
          }
        },
        {
          label: this.$i18n.t('user.contracts.startDate'),
          field: "startDate",
          type: "date",
          filterOptions: {
            enabled: true,
            placeholder: this.$i18n.t('user.contracts.startDate'),
          }
        },
        {
          label: this.$i18n.t('user.contracts.endDate'),
          field: "endDate",
          filterable: true,
          type: "date",
          filterOptions: {
            enabled: true,
            placeholder: this.$i18n.t('user.contracts.endDate'),
          }
        },
        ...this.checkedOption,
      ]
    }
  }
}
</script>

<style lang="scss">
.minWidth {
  min-width: 260px;
}

.vgt-wrap__actions-footer {
  padding: 7px;
}

.sidebarContract {
  padding: 19px;
}

.floatDropDown {
  z-index: 1000;
}

.flatpickr-input {
  width: 100%;
}

.vgt-table th {
  font-size: 14px !important;
  text-align: left !important;
}

.sidebarContract legend {
  border-bottom: solid;
  margin-bottom: 16px;
}
</style>
